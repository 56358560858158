import React from 'react';

const StartButton = ({ toggleMenu }) => {
  return (
    <button className="start-button" onClick={toggleMenu}>
      Start
    </button>
  );
};

export default StartButton;
