import React from 'react';

const StartMenu = ({ isVisible, onOpenPlayer, onOpenCmd }) => {
  return (
    isVisible && (
      <div className="start-menu">
        <ul>
          <li onClick={onOpenPlayer}><a href="#">Media Player</a></li>
          <li><a href="https://dexscreener.com/" target="_blank" rel="noopener noreferrer">DexScreener</a></li>
          <li><a href="https://x.com/AGRonSol" target="_blank" rel="noopener noreferrer">X</a></li>
          <li><a href="https://t.me/AGRonSol" target="_blank" rel="noopener noreferrer">Telegram</a></li>
          <li onClick={onOpenCmd}><a href="#">Command Prompt</a></li>
        </ul>
      </div>
    )
  );
};

export default StartMenu;
