import React from 'react';
import StartButton from './StartButton';

const Taskbar = ({ toggleMenu }) => {
  return (
    <div className="taskbar">
      <StartButton toggleMenu={toggleMenu} />
    </div>
  );
};

export default Taskbar;
