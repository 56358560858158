import React from 'react';
import './Icon.css';

const Icon = ({ onClick }) => {
  return (
    <div className="icon" onClick={onClick}>
      <img src="/images/icon.png" alt="Icon" />
    </div>
  );
};

export default Icon;
