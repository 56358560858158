import React from 'react';
import Draggable from 'react-draggable';
import './Modal.css';

const Modal = ({ isVisible, onClose, mp3Url }) => {
  if (!isVisible) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <Draggable handle=".modal-header">
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-header">
            <span className="modal-title">Media Player</span>
            <button className="modal-close-button" onClick={onClose}>X</button>
          </div>
          <div className="modal-body">
            <audio controls>
              <source src={mp3Url} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
      </Draggable>
    </div>
  );
};

export default Modal;
