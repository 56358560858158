import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import './CommandPrompt.css';

const CommandPrompt = ({ isVisible, onClose }) => {
  const [output, setOutput] = useState("C:\\");
  const [typingIndex, setTypingIndex] = useState(0);
  const [lines, setLines] = useState([]);

  useEffect(() => {
    if (!isVisible) {
      setOutput("C:\\");
      setTypingIndex(0);
      setLines([]);
      return;
    }

    const message = "run agr.exe";
    if (typingIndex < message.length) {
      const timeoutId = setTimeout(() => {
        setOutput(output + message[typingIndex]);
        setTypingIndex(typingIndex + 1);
      }, 500);

      return () => clearTimeout(timeoutId);
    } else if (typingIndex === message.length) {
      const timeoutId = setTimeout(() => {
        setLines([...lines, output, "..."]);
        setOutput("");
        setTypingIndex(typingIndex + 1);
      }, 500);

      return () => clearTimeout(timeoutId);
    } else if (typingIndex === message.length + 1) {
      const timeoutId = setTimeout(() => {
        setOutput("Artificial General Retardation achieved");
        setTypingIndex(typingIndex + 1);
      }, 1500);

      return () => clearTimeout(timeoutId);
    } else {
      const timeoutId = setTimeout(() => {
        setLines([...lines, output]);
        setOutput("Artificial General Retardation achieved");
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [isVisible, typingIndex, output, lines]);

  if (!isVisible) return null;

  return (
    <div className="cmd-overlay" onClick={onClose}>
      <Draggable handle=".cmd-header">
        <div className="cmd-window" onClick={e => e.stopPropagation()}>
          <div className="cmd-header">
            <span className="cmd-title">Command Prompt</span>
            <button className="cmd-close-button" onClick={onClose}>X</button>
          </div>
          <div className="cmd-body">
            {lines.map((line, index) => (
              <div key={index}>{line}</div>
            ))}
            <div>{output}</div>
          </div>
        </div>
      </Draggable>
    </div>
  );
};

export default CommandPrompt;
