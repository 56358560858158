import React, { useState } from 'react';
import Draggable from 'react-draggable';
import './Win95Window.css';

const Win95Window = ({ isVisible, onClose, images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };

  if (!isVisible) return null;

  return (
    <div className="win95-overlay" onClick={onClose}>
      <Draggable handle=".win95-header">
        <div className="win95-window" onClick={e => e.stopPropagation()}>
          <div className="win95-header">
            <span className="win95-title">Image Viewer</span>
            <button className="win95-close-button" onClick={onClose}>X</button>
          </div>
          <div className="win95-content">
            <img src={images[currentIndex]} alt="Content" className="win95-image" />
            <div className="win95-controls">
              <button onClick={handlePrev}>&lt; Prev</button>
              <button onClick={handleNext}>Next &gt;</button>
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  );
};

export default Win95Window;
