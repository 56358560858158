import React, { useState } from 'react';
import Taskbar from './components/Taskbar';
import StartMenu from './components/StartMenu';
import Modal from './components/Modal';
import CommandPrompt from './components/CommandPrompt';
import Icon from './components/Icon';
import Win95Window from './components/Win95Window';
import './App.css';

function App() {
  const [menuVisible, setMenuVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [cmdVisible, setCmdVisible] = useState(false);
  const [win95Visible, setWin95Visible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const openPlayer = (e) => {
    e.preventDefault();
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const openCmd = (e) => {
    e.preventDefault();
    setCmdVisible(true);
  };

  const closeCmd = () => {
    setCmdVisible(false);
  };

  const openWin95 = () => {
    setWin95Visible(true);
  };

  const closeWin95 = () => {
    setWin95Visible(false);
  };

  const images = [
    "/images/image1.png",
    "/images/image2.png",
    "/images/image3.png"
  ];

  return (
    <div className="App">
      <img src="/images/retardios.png" alt="RetardIOS" className="background-image" />
      <Icon onClick={openWin95} />
      <Taskbar toggleMenu={toggleMenu} />
      <StartMenu isVisible={menuVisible} onOpenPlayer={openPlayer} onOpenCmd={openCmd} />
      <Modal isVisible={modalVisible} onClose={closeModal} mp3Url="https://cyan-top-lobster-928.mypinata.cloud/ipfs/QmXwLiQyVTcBnd6VdwJFENZBkr7Ymx2rzFywtyp7d3JEUE" />
      <CommandPrompt isVisible={cmdVisible} onClose={closeCmd} />
      <Win95Window isVisible={win95Visible} onClose={closeWin95} images={images} />
    </div>
  );
}

export default App;
